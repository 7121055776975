import React from "react"

const Header = () => {
  return (
    <footer>
      <div class="s-footer-meta">
        <div className="s-footer-meta__container">
          <div className="s-footer-meta__row">
            <div className="s-footer-meta__item">www.arkesipelastaja.fi</div>
            <div className="s-footer-meta__item">
              <a href="mailto:jeremias@arkesipelastaja.fi">
                jeremias@arkesipelastaja.fi
              </a>
            </div>
            <div className="s-footer-meta__item">Y-tunnus 2942327-7</div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Header
