import React from "react"
import kuva from "../../images/kuva1.jpg"

const Hero = () => {
  return (
    <div class="s-basic">
      <div
        className="c-background-image"
        style={{ backgroundImage: `url(${kuva})` }}
      ></div>
      <div className="s-basic__container">
        <div className="s-basic__row">
          <div className="s-basic__item">
            <div className="c-hero">
              <div className="c-hero__content">
                <h1>Arkesi pelastaja </h1>
                <h2 className="c-hero__subtitle">
                  - ja sinulla jää enemmän aikaa itsellesi.
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
