import React from "react"
import Button from "../../components/Button"

const Header = () => {
  return (
    <div class="s-header">
      <div className="s-header__container">
        <div className="s-header__row">
          <div className="s-header__logo">
            <div className="c-logo">
              Arkesi<strong>pelastaja</strong>
            </div>
          </div>
          <div className="s-header__nav">
            <Button
              url="mailto:jeremias@arkesipelastaja.fi"
              label="Ota yhteyttä!"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
