import React from "react"

const Contact = () => {
  return (
    <div className="c-contact">
      <div className="c-contact__content">
        <h1 class="c-contact__title">Ota yhteyttä!</h1>
        <p>
          Olen täyden palvelun mies ja uskon, että varmasti löydämme ratkaisun
          arkesi ongelmiin. Ota rohkeasti yhteyttä ja kysy tarjous, niin
          sovitaan yhdessä, miten teemme arjestasi sujuvampaa.
        </p>
        <p>
          Parhaiten tavoitat minut sähköpostitse osoitteesta{" "}
          <a href="mailto:jeremias@arkesipelastaja.fi">
            jeremias@arkesipelastaja.fi
          </a>
        </p>
      </div>
    </div>
  )
}

export default Contact
