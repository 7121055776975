import React from "react"
import kuva from "../../images/kuva1.jpg"

const Content = () => {
  return (
    <div class="s-basic u-collapse">
      <div className="s-basic__container">
        <div className="s-basic__row">
          <div
            className="s-basic__item"
            style={{ maxWidth: "775px", margin: "0 auto" }}
          >
            <div style={{ textAlign: "center" }}>
              <h2>Onnittelut siitä, että olet löytänyt arkesi pelastajan!</h2>
              <p>
                Arkesi pelastaja on palveluntarjoaja, jolle voit ulkoistaa
                kaikki arjen pienet työt ja "hanttihommat", näin sinulla jää
                enemmän aikaa itsellesi.
              </p>
              <p>
                Hoidan mm. nurmikon leikkuut, haravoinnit, räystäskourujen
                puhdistukset, putoamisvaarallinen työ, lumenpudotukset,
                kalusteasennukset- ja kokoamiset. Kauttani järjestyy myös
                esimerkiksi putkityöt (pesukoneiden, hanan tai altaiden
                kytkennät).
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Content
