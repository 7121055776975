import React from "react"
import Header from "../sections/Header"
import Hero from "../sections/Hero"
import Content from "../sections/Content"
import Contact from "../sections/Contact"
import Footer from "../sections/Footer"

import "../styles/main.scss"

export default function Home({ data }) {
  console.log({ data })
  return (
    <>
      <Header></Header>
      <Hero></Hero>
      <Content></Content>
      <Contact></Contact>
      <Footer></Footer>
    </>
  )
}
