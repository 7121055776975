import React from 'react';

const Button = ({ url, label }) => {
    return (
        <a className="c-button c-button--primary" href={url}>
            {label}
        </a>
    )
}

export default Button;